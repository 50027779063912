<template>
  <v-expansion-panel
    active-class="active"
    :disabled="!readOnlyOrderInvoicingTab"
  >
    <v-expansion-panel-header
      class="header secondary--text"
      expand-icon="mdi-menu-down"
    >
      <template v-slot:actions>
        <v-icon color="white"> fas fa-angle-up </v-icon>
      </template>
      <div>
        <h6 class="text-h6 font-weight-bold">N° {{ receipt.code }}</h6>
        <span class="caption">Emitido em: {{ receipt.emission_date }}</span>
        <div class="subtitle-2">{{ receipt.operation_nature }}</div>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content color="grey lighten-3">
      <fieldset class="u-fieldset-border">
        <legend class="ml-5">
          <h6 class="overline primary--text mx-1">Produtos</h6>
        </legend>
        <v-row dense>
          <v-col
            v-if="receipt.products.length > 0"
            cols="12"
            md="12"
          >
            <ReceiptProductsTable
              :tableHeader="tableHeader"
              :products="normalized_products"
              :isInvoiced="receipt.is_invoiced"
              :totalValue="receipt.value"
              @removeReceiptProduct="(e) => receipt.products.splice(e, 1)"
              @setTotalNF="(e) => (receipt.value = e)"
            />
          </v-col>
          <v-col
            v-else
            cols="12"
            md="12"
            class="text-center pa-3"
          >
            <h5 class="text-h5 grey--text">Nenhum produto adicionado</h5>
          </v-col>
        </v-row>
      </fieldset>
      <fieldset class="u-fieldset-border pa-5">
        <legend>
          <h6 class="overline primary--text mx-1">Vencimentos</h6>
        </legend>
        <v-btn
          :disabled="receipt.is_invoiced == true"
          @click="showAutoGenerate = !showAutoGenerate"
          class="mb-3"
          color="primary"
          outlined
          rounded
        >
          Parcelas automáticas
        </v-btn>
        <v-row
          v-if="showAutoGenerate"
          dense
        >
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="autoGenerateDeadline"
              label="Vencimento"
              v-mask="['##/##/####']"
              dense
              outlined
              rounded
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="autoGeneratePortion"
              label="Parcelas"
              dense
              outlined
              rounded
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-btn
              v-if="receipt.deadlines.length == 0"
              :disabled="!autoGenerateDeadline || !autoGeneratePortion"
              @click="generateDeadlines"
              color="primary"
              width="100%"
              rounded
              outlined
            >
              Gerar
            </v-btn>
            <v-btn
              v-else
              @click="receipt.deadlines = []"
              color="primary"
              width="100%"
              rounded
              outlined
            >
              Limpar
            </v-btn>
          </v-col>
        </v-row>

        <ReceiptDeadlines
          v-for="(deadline, idx) in receipt.deadlines"
          :key="idx"
          :isInvoiced="receipt.is_invoiced"
          :deadline="deadline"
          :deadlineNumber="idx + 1"
          @removeDeadline="removeDeadline(deadline)"
        />

        <v-row dense>
          <v-col
            cols="12"
            md="12"
          >
            <v-btn
              @click="addDeadline"
              v-if="!receipt.is_invoiced"
              color="primary"
              small
              icon
              class="primary"
            >
              <v-icon
                color="white"
                small
                >fas fa-plus</v-icon
              >
            </v-btn>
          </v-col>
        </v-row>
      </fieldset>
      <v-textarea
        :disabled="receipt.is_invoiced == true"
        v-model="receipt.observation"
        label="Observações"
        class="my-4"
        auto-grow
        rounded
        outlined
        hide-details
      />
      <v-row>
        <v-col
          align="end"
          cols="12"
        >
          <v-btn
            :disabled="receipt.is_invoiced == true || !showEditOrders"
            @click="invoiceNF"
            color="primary"
            rounded
            >Faturar NF</v-btn
          >
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    receipt: Object,
    receipt_products: Array,
  },
  components: {
    ReceiptProductsTable: () => import('./ReceiptProductsTable'),
    ReceiptDeadlines: () => import('./ReceiptDeadlines'),
  },
  data: () => ({
    showCustomItem: null,
    showAutoGenerate: false,
    autoGenerateMenu: null,
    autoGenerateDeadline: null,
    autoGeneratePortion: null,
    tableHeader: [
      { title: 'Imagem', align: '' },
      { title: 'Nome', align: '' },
      { title: 'Qtd. Faturada', align: 'text-center' },
      { title: 'Qtd. Remover', align: 'text-center' },
    ],
    tab: null,
    items: ['Produtos', 'Vencimentos', 'Observações'],
    allProducts: [],
  }),
  watch: {
    receipt: {
      handler(newvalue) {
        this.$emit('setReceipt', newvalue)
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('comercial', ['showEditOrders', 'readOnlyOrderInvoicingTab']),
    receiptTotal() {
      var total = 0
      if (this.receipt.deadlines.length > 0) {
        this.receipt.deadlines.map((el) => {
          if (el.value && (el.value.includes('.') || el.value.includes(','))) {
            total =
              total +
              parseFloat(
                el.value.replace('.', '').replace('.', '.').replace(',', '.')
              )
          }
        })
      }
      return total
    },
    normalized_products() {
      if (this.allProducts.length == 0) {
        this.receipt_products.map((receipt_product) => {
          this.receipt.products.map((el) => {
            if (
              receipt_product.id === el.order_product_id ||
              receipt_product.id === el.id
            ) {
              this.allProducts.push({
                ...receipt_product,
                done_quantity: el.done_quantity,
              })
            }
          })
        })
      }

      return this.allProducts
    },
  },
  methods: {
    addDeadline() {
      this.receipt.deadlines.push({})
    },
    removeDeadline(deadline) {
      let idx = this.receipt.deadlines.indexOf(deadline)
      this.receipt.deadlines.splice(idx, 1)
    },
    generateDeadlines() {
      var total = this.receipt.value.replace('.', '').replace(',', '.')

      var portion = total / this.autoGeneratePortion
      var deadlineDate = new Date(
        this.autoGenerateDeadline.split('/').reverse().join('-')
      )

      deadlineDate.setDate(deadlineDate.getDate())

      for (let i = 0; i < this.autoGeneratePortion; i++) {
        console.log(deadlineDate.toISOString().split('T')[0])
        this.receipt.deadlines.push({
          deadline: deadlineDate
            .toISOString()
            .split('T')[0]
            .split('-')
            .reverse()
            .join('/'),
          value: portion.toFixed(2).toLocaleString('pt-BR'),
        })
        deadlineDate.setDate(deadlineDate.getDate() + 30)
      }
    },
    removeProduct(obj) {
      this.$emit('removeProduct', obj)
    },
    invoiceNF() {
      this.$store.commit('setModal', {
        show: true,
        component: 'warning',
        text: 'Tem certeza que deseja faturar essa nota?',
        caption: 'Uma vez faturada, a nota não poderá ser editada.',
        confirm: () => {
          let error_counter = 0

          this.receipt.products.map((el) => {
            if (el.to_invoice_qtty > el.produced_qtty) {
              error_counter++
            }
          })

          if (error_counter > 0) {
            this.$store.commit('setModal', {
              show: true,
              component: 'error',
              text: 'Ops! Algo deu errado',
              caption: 'Quantidade a faturar excede a quantidade produzida',
              confirm: () => {
                this.$store.commit('setModal', {
                  show: false,
                })
              },
            })

            return
          }

          this.$api
            .post('orders/invoicings/invoice_receipt', {
              ...this.receipt,
              products: this.normalized_products,
            })
            .then(() => {
              this.$emit('invoicedNF')
            })
            .catch((err) => {
              console.error(err)
            })
            .finally(() => {
              this.$store.commit('setModal', {
                show: false,
              })
            })
        },
        negate: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.active .header {
  background: $primary !important;
  color: white !important;
  transition: background 0.3s;
}

.u-overflow-height {
  max-height: 350px;
  overflow: auto;
}

.u-fieldset-border {
  border-radius: 7px;
  border-color: #eee;
}
</style>
